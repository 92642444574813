import React from "react";
import "./contact.css";
import { Zoom } from "react-reveal";

const Contact = () => {
  let timer = setInterval(() => {
    let inputs = document.querySelectorAll(".input");

    function focusFunc() {
      let parent = this.parentNode.parentNode;
      parent.classList.add("focus");
    }

    function blurFunc() {
      let parent = this.parentNode.parentNode;
      if (this.value === "") {
        parent.classList.remove("focus");
      }
    }

    inputs.forEach((input) => {
      input.addEventListener("focus", focusFunc);
      input.addEventListener("blur", blurFunc);
    });
  }, 500);
  return (
    <div className="contact">
      <div className="container">
        <div className="back">
          <Zoom cascade>
            <div className="box">
              <div className="text">
                <h2>Contact Information</h2>
                <p>We Are waiting For Your Message</p>
              </div>
              <div className="info">
                <div className="line">
                  <i className="fas fa-phone-volume fa-fw"></i>
                  <a href="tel:+966500338866">+966500338866</a>
                </div>
                <div className="line">
                  <i className="fa-regular fa-envelope"></i>
                  <a href="mailto:aaljafin@etco.com.sa">aaljafin@etco.com.sa</a>
                </div>
                <div className="line">
                  <i className="fas fa-map-marker-alt fa-fw"></i>
                  <a href="https://www.google.com/maps/dir/?api=1&destination=24.8034540105966%2C46.7494944147601">
                    Riyadh - Granada district - Dammam streat
                  </a>
                </div>
              </div>
              <div className="social">
                <a
                  href="https://wa.me/966500338866"
                  target="_blank"
                  rel="noreferrer"
                  className="whatsapp"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
                <a
                  href="https://twitter.com/etco_sa"
                  target="_blank"
                  rel="noreferrer"
                  className="twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/energy-twoer-company/"
                  target="_blank"
                  rel="noreferrer"
                  className="linkedin"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </Zoom>
          <div className="con">
            <form
              method="POST"
              action="https://formsubmit.co/aaljafin@etco.com.sa"
            >
              <input type="hidden" name="_template" value="box" />
              <input
                type="hidden"
                name="_autoresponse"
                value="We Have Recieve Your Message. We Will Replay To You As Soon As Posible."
              />
              <input
                type="hidden"
                name="_next"
                value="http://localhost:3000/"
              />
              <h2>Send A Message</h2>
              <div className="inputs">
                <div className="line">
                  <div className="icon">
                    <i className="fa-solid fa-signature"></i>
                  </div>
                  <div className="in">
                    <h3>Name</h3>
                    <input type="text" name="name" className="input" required />
                  </div>
                </div>
                <div className="line">
                  <div className="icon">
                    <i className="fa-solid fa-envelope"></i>
                  </div>
                  <div className="in">
                    <h3>Email</h3>
                    <input
                      type="email"
                      name="email"
                      className="input"
                      required
                    />
                  </div>
                </div>
                <div className="line">
                  <div className="icon">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                  <div className="in">
                    <h3>Phone Number</h3>
                    <input
                      type="tel"
                      name="number"
                      className="input"
                      required
                    />
                  </div>
                </div>
                <div className="line">
                  <div className="icon">
                    <i className="fa-solid fa-message"></i>
                  </div>
                  <div className="in">
                    <h3>Your Message</h3>
                    <textarea
                      required
                      className="input"
                      name="message"
                    ></textarea>
                  </div>
                </div>
                <input type="submit" className="log" value="Send" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
