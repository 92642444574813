import React from "react";
import { Link } from "react-router-dom";
import "./landing.css";
import { Zoom } from "react-reveal";

const Landing = () => {
  return (
    <div className="landing">
      <div className="container">
        <Zoom cascade>
          <div className="text">
            <div className="writer">
              <h1>Energy</h1>
              <ul>
                <li style={{ "--clr": "#fe8a11" }}>Generation</li>
                <li style={{ "--clr": "#854ad6" }}>Distribution</li>
                <li style={{ "--clr": "#f41e5b" }}>Transmission</li>
              </ul>
            </div>
            {/* We Provide Greate Energy Servises */}
            <p>
              We are a corporation based in Saudi Arabia, and our main focus
              areas are energy, power, and infrastructure. Our primary goal is
              to provide reliable and high-quality services to our clients.
            </p>
            <div className="links">
              <Link to="contact">Contact Us</Link>
              <Link to="industries">Our Industries</Link>
            </div>
          </div>
        </Zoom>
        <img src={require("../../imgs/landing.webp")} alt="Landing" />
      </div>
    </div>
  );
};

export default Landing;
