let inds = [
  {
    id: 1,
    img: "ind1",
    name: "Trading",
    des: "In 2018, Energy Towers Company for Trading was established to provide electrical equipment. we have formed exclusive partnerships with manufacturers and companies.",
    color: "#fe8a11",
    url: "trading",
  },
  {
    id: 2,
    img: "ind2",
    name: "Contracting",
    des: "Since 2018, Energy Tower Company for contracting has been dedicated to delivering exceptional electrical installations and turnkey projects to the electricity sector.",
    color: "#854ad6",
    url: "contracting",
  },
  {
    id: 3,
    img: "ind3",
    name: "Engineering",
    des: "Energy Towers Company for Engineering provides engineering design, project management, and consulting services. It was founded in 2021.",
    color: "#f41e5b",
    url: "engineering",
  },
];
export default inds;

export let msgs = [
  {
    id: "01",
    img: "1.png",
    color: "#f41e5b",
    name: "Vision",
    text: "We are confident in our resources and understanding of customers' needs. We prioritize quality and self-sufficiency, drawing on our experience to guide us towards our true potential.",
  },
  {
    id: "02",
    img: "2.png",
    color: "#fe8a11",
    name: "Values",
    text: "At the very core of every decision we make, lies our values - a reflection of our ethics and attitude, which are deeply rooted in the very essence of our name.",
  },
  {
    id: "03",
    img: "3.png",
    color: "#1ed3d7",
    name: "Advanced",
    text: "We are forward thinkers. We insist on always being one-step ahead, constantly injecting best practice in order to improve efficiency. We believe in technology and invest in the future.",
  },
  {
    id: "04",
    img: "4.png",
    color: "#1384c8",
    name: "Sincere",
    text: "We are sincere in our commitment to realize our vision. We are genuine in our dedication to being good global citizens. Our sincerity is a result of our long history and culture. We are earnest in our desire for growth.",
  },
  {
    id: "05",
    img: "5.png",
    color: "#1b4c91",
    name: "Truthful",
    text: "We are guided by our integrity. We are transparent in all of our dealings and honest in our evaluations. We ensure that we only align our interests with those that carry the same conformity to actuality.",
  },
  {
    id: "06",
    img: "6.png",
    color: "#854ad6",
    name: "Responsible",
    text: "We are committed to our stakeholders, our partners, our suppliers and our surrounding communities. We are accountable for each decision we take. We are obligated to enhancing the lives of our people.",
  },
  {
    id: "07",
    img: "7.png",
    color: "#e64926",
    name: "Adept",
    text: "We are proficient, capable and our expertise is the result of our five decades of knowledge. Our skill set guides us towards delivering quality in each of our businesses.",
  },
];

export let mission = [
  {
    id: 1,
    text: "To expand our range of products and services through strategic investments in new businesses and categories. This approach enables us to achieve horizontal and vertical integration, ultimately enhancing the quality of our offerings and the value we provide to our customers.",
    color: "#fe8a11",
  },
  {
    id: 2,
    text: "To strategically allocate resources towards capitalizing on the energy potential of our current markets, while concurrently investigating new energy markets and business prospects.",
    color: "#854ad6",
  },
  {
    id: 3,
    text: "To fully comprehend the needs and requirements of our valued clientele and deliver superior-quality goods and services that exceed their expectations. Our brand, Energy Towers Companies, is synonymous with high standards and exceptional quality.",
    color: "#f41e5b",
  },
  {
    id: 4,
    text: "Our objective is to motivate and enable our team members to unlock their full potential and accomplish remarkable feats. Concurrently, we aim for sustainable expansion that benefits all stakeholders.",
    color: "#1b4c91",
  },
];

export let industries = [
  {
    id: 1,
    img: "section1.png",
    name: "Trading",
    company: "Energy Tower Company for Trading",
    text: "Energy Towers Company for Trading was founded in 2018 to provide its clients with high-quality electrical equipment and tools. As a reputable supplier in the industry, we pride ourselves on maintaining exclusive agency agreements with a range of distinguished manufacturers and companies. Our commitment to excellence and professionalism ensures that we consistently deliver top-notch products and services to our valued customers.",
    url: "trading",
    color: "#fe8a11",
  },
  {
    id: 2,
    img: "section2.png",
    name: "Contracting",
    company: "Energy Tower Company for Contracting",
    text: "Energy Towers Company for Contracting was founded in 2018 and is dedicated to providing exceptional services in the realm of electrical projects. We specialize in installations as well as turnkey projects for the electricity sector. Our team is highly skilled and committed to delivering high-quality workmanship that meets the needs of our clients. We take pride in our work and strive to maintain a standard of excellence in all aspects of our business.",
    url: "contracting",
    color: "#854ad6",
  },
  {
    id: 3,
    img: "section3.png",
    name: "Engineering",
    company: "Energy Tower Company for Engineering",
    text: "The Energy Towers Company for Engineering was founded in 2021 with the specific aim of providing exceptional engineering services to its clients. This includes the execution of engineering designs, project management, and consulting solutions. Our team is dedicated to delivering high-quality results to our clients with the utmost professionalism.",
    url: "engineering",
    color: "#f41e5b",
  },
];

export let trading = {
  name: "Trading",
  company: "Energy Tower Company for Trading",
  title:
    "In 2018, Energy Towers Company for Trading was established to provide electrical equipment. we have formed exclusive partnerships with manufacturers and companies.",
  color: "#fe8a11",
  services: [
    "1- Supply of electrical equipment required for project",
    "2- Agent for companies and factories specializing in electrical equipment",
  ],
  clinet: ["saudi.jpeg", "aramco.jpeg", "sabic.png", "marafiq.png"],
  projects: [
    {
      name: "Supply 13.8KV Switchgear for S/S 7809 & S/S 7808",
      client: "Saudi Electricity Company",
      img: "majlat.jpeg",
      contractor: "Majlat",
      price: "4,981,898 SAR",
      description:
        "Provide 13.8KV Switchgear for S/S 7809 & S/S 7808 in Qaseem Area in Saudi Arabia for Saudi Electricity Company with Total 44 Panels ",
    },
  ],
};

export let contracting = {
  name: "Contracting",
  company: "Energy Tower Company for Contracting",
  title:
    "Since 2018, Energy Tower Company for Contracting has been dedicated to delivering exceptional electrical installations and turnkey projects to the electricity sector.",
  color: "#854ad6",
  services: [
    "1- Electrical Installation for Substation ",
    "2- Turnkey Project in Electrcity Field",
  ],
  clinet: [
    "saudi.jpeg",
    "aramco.jpeg",
    "sabic.png",
    "marafiq.png",
    "abb.jpg",
    "semines.jpg",
    "altukhi.png",
  ],
  projects: [
    {
      name: "Electrical Erection for Substation # 8712",
      client: "Saudi Electricity Company",
      img: "abb.jpg",
      contractor: "ABB",
      price: "1,826,750 SAR",
      description:
        "Electrical Erection for Substation # 8712  in Al-Kharaj - KSA (132KV Substation)",
    },
    {
      name: "Electrical Erection for Substation # 8709 ",
      client: "Saudi Electricity Company",
      img: "abb.jpg",
      contractor: "ABB",
      price: "1,188,000 SAR",
      description:
        "Electrical Erection for Substation # 8709 in Wadi Al-Dwaser , KSA  (132KV Substation)",
    },
    {
      name: "Electrical Erection for Substation # 8705",
      client: "Saudi Electricity Company",
      img: "abb.jpg",
      contractor: "ABB",
      price: "705,000 SAR",
      description:
        "Electrical Erection for Substation # 8705 in Al-Kharj , KSA  (132KV Substation)",
    },
    {
      name: "Electrical Erection for AlKharaj SVC",
      client: "Saudi Electricity Company",
      img: "semines.jpg",
      contractor: "Semines",
      price: "1,950,000 SAR",
      description: "Electrical Erection for AlKharj SVC in Al-Kharj , KSA",
    },
    {
      name: "Electrical Erection for AlQweah SVC",
      client: "Saudi Electricity Company",
      img: "semines.jpg",
      contractor: "Semines",
      price: "500,000 SAR",
      description: "Electrical Erection for AlQweah SVC in AlQweah , KSA",
    },
    {
      name: "Electrical Erection for Substation # 9063",
      client: "Saudi Electricity Company",
      img: "altukhi.png",
      contractor: "Altokhi",
      price: "500,000SAR",
      description:
        "Electrical Erection for Substation # 9063 in Al-Jilah , KSA  (380KV Substation)",
    },
    {
      name: "Electrical Erection for Substation # 9775",
      client: "Saudi Electricity Company",
      img: "altukhi.png",
      contractor: "Altokhi",
      price: "600,000SAR",
      description:
        "Electrical Erection for Substation # 9063 in Khamis Mushit , KSA  (380KV Substation)",
    },
  ],
};

export let engineering = {
  name: "Engineering",
  company: "Energy Tower Company for Engineering",
  title:
    "Energy Towers Company for Engineering provides engineering design, project management, and consulting services. It was founded in 2021.",
  color: "#f41e5b",
  services: [
    "1- Engineering Design ",
    "2- Project Management",
    "3- Engineering Consulting",
  ],
  clinet: [
    "zj.png",
    "advance.jpg",
    "nour.jpg",
    "carlo.jpg",
    "altukhi.png",
    "ajlan.png",
  ],
  projects: [
    {
      name: "Issue FAC(Final Acceptance Certificate )",
      client: "Saudi Electricity Company",
      img: "nour.jpg",
      contractor: "Nour",
      price: "50,000 SAR",
      description:
        "Issue FAC(Final Acceptance Certificate )  for Tows Projects",
    },
    {
      name: "Close Project and Get Final Payment (total amount : 8MSAR)",
      client: "Saudi Electricity Company",
      img: "nour.jpg",
      contractor: "Nour",
      price: "150,000 SAR",
      description:
        "Close Old projects and get the final payment for these project with total amount ( 8 MSAR)",
    },
    {
      name: "Prequalification for 132 KV Substation (EPC) with SEC",
      client: "Saudi Electricity Company",
      img: "advance.jpg",
      contractor: "Advance Energy Company",
      price: "50,000 SAR",
      description: "Prequalification for 132 KV Substation (EPC) with SEC",
    },
    {
      name: "Prequalification for 132KV Substation (EPC) with SEC",
      client: "Saudi Electricity Company",
      img: "carlo.jpg",
      contractor: "Carlo Gavazzi Arabia",
      price: "30,000 SAR",
      description: "Prequalification for 132 KV Substation (EPC) with SEC",
    },
    {
      name: "Issue FAC (Final Acceptance Certificate )",
      client: "Saudi Electricity Company",
      img: "altukhi.png",
      contractor: "Altokhi",
      price: "50,000 SAR",
      description: "Issue FAC(Final Acceptance Certificate )  for one Project",
    },
    {
      name: "Prequalification Chint for 132 KV Substation (Manufacturer ) with SEC",
      client: "Saudi Electricity Company",
      img: "ajlan.png",
      contractor: "Ajlan & Bro",
      price: "600,000 SAR",
      description:
        "Prequalification for 132 KV Substation (Manufacturer) with SEC",
    },
  ],
};
