import React from "react";
import "./about.css";
import { Industry } from "../../components";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import inds from "../../data";

const About = () => {
  return (
    <div className="about">
      <h2 className="main-title">Who We Are</h2>
      <Fade>
        <div className="top back">
          <Zoom cascade>
            <div className="container">
              <img src={require("../../imgs/logo.webp")} alt="Logo" />
              <h3>
                Energy Towers Companies (ETCO) is a diversified group of
                companies with investments in a wide range of Power and Energy
                that include:
              </h3>
            </div>
          </Zoom>
        </div>

        <div className="ind">
          <div className="container">
            {inds.map((e) => {
              return (
                <Fade key={e.id} bottom>
                  <Industry props={e} />
                </Fade>
              );
            })}
          </div>
        </div>

        <div className="leader back">
          <h2 className="main-title">LEADERSHIP</h2>
          <div className="container">
            <Zoom cascade>
              <img src={require("../../imgs/leader.png")} alt="Leader" />
              <div className="text">
                <h3>Eng. Ahmed Aljafin</h3>
                <p className="title">General Manager</p>
                <p>
                  <span>Eng. Ahmed Aljafin</span>, our founder, established
                  Energy Towers Companies in 2018 in the Kingdom of Saudi
                  Arabia. His vision is the inspiration that guides the
                  Companies forward.
                </p>
              </div>
            </Zoom>
          </div>
        </div>

        <div className="bottom back">
          <h2 className="main-title">Quality</h2>
          <Zoom cascade>
            <div className="container">
              <img src={require("../../imgs/stars.png")} alt="quality" />
              <p>
                Energy Towers Companies deliver quality products and services to
                customers across Saudi. Energy Towers Companies is proud of its
                history and is committed to its future. The Companies operate in
                compliance to the Companies's stringent quality control and
                assurance policies. Cutting-edge technology, state-of-the-art
                solutions and advance management practices are at the heart of
                our organization.
              </p>
            </div>
          </Zoom>
        </div>
      </Fade>
    </div>
  );
};

export default About;
