import React from "react";
import "./projects.css";
import Zoom from "react-reveal/Zoom";

const Projects = ({ props }) => {
  return (
    <div className="projects">
      <div className="main-title">Projects</div>
      <div className="container">
        {props.projects.map((e) => {
          return (
            <Zoom cascade key={e.name}>
              <div className="project">
                <img src={require(`../../imgs/${e.img}`)} alt="Project" />
                <div className="text">
                  <h3>{e.name}</h3>
                  <ul>
                    <li>
                      Client:
                      <span>{e.client}</span>
                    </li>
                    <li>
                      Company:
                      <span>{e.contractor}</span>
                    </li>
                    <li>
                      Price:
                      <span>{e.price}</span>
                    </li>
                    <li>
                      Description:
                      <span>{e.description}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </Zoom>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
