import React from "react";
import Zoom from "react-reveal/Zoom";
import "./industry.css";
import { Link } from "react-router-dom";

const Industry = ({ props }) => {
  return (
    <div className="box" style={{ "--clr": props.color }}>
      <div className="img">
        <img src={require(`../../imgs/${props.img}.png`)} alt="Industry" />
      </div>
      <Zoom cascade>
        <div className="text">
          <h2>{props.name}</h2>
          <p>{props.des}</p>
        </div>
        <Link to={`/${props.url}`} name="Industry Page">
          More Information
        </Link>
      </Zoom>
    </div>
  );
};

export default Industry;
