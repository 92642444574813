import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { Zoom } from "react-reveal";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <Zoom cascade>
          <img src={require("../../imgs/logo.webp")} alt="LOGO" />
          <div className="text">
            <div className="top">
              <h3>Energy Towers Companies</h3>
              {/* <p>
                We are Saudi national corporations concerned with energy, power
                generation and infrastructure for a reliable and high quality
                service.
              </p> */}
            </div>
            <ul className="links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/industries">Industries</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
            </ul>
            <div className="bottom">
              <h4>All Rights Reserved</h4>
              <div className="social">
                <a
                  href="https://wa.me/966500338866"
                  aria-label="whatsapp"
                  target="_blank"
                  rel="noreferrer"
                  className="whatsapp"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
                <a
                  href="https://twitter.com/etco_sa"
                  aria-label="twitter"
                  target="_blank"
                  rel="noreferrer"
                  className="twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/energy-twoer-company/"
                  aria-label="linkedin"
                  target="_blank"
                  rel="noreferrer"
                  className="linkedin"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default Footer;
