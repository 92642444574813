import React from "react";
import inds, { msgs, mission } from "../../data";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "./home.css";
import { Industry, Landing, Card, Mission } from "../../components";

const Home = () => {
  return (
    <div>
      <Landing />
      {/* Start Mession */}
      <div className="missions">
        <h1 className="main-title">MISSION</h1>
        <div className="container">
          {mission.map((e) => {
            return (
              <Fade key={e.id} bottom>
                <Mission props={e} />
              </Fade>
            );
          })}
        </div>
      </div>
      {/* End Mession */}
      {/* Start Cards */}
      <div className="cards">
        <h1 className="main-title">Advantages</h1>
        <div className="container">
          {msgs.map((e) => {
            return (
              <Fade key={e.id} bottom>
                <Card props={e} />
              </Fade>
            );
          })}
        </div>
      </div>
      {/* End Cards */}
      {/* Start Industries */}
      <div className="ind">
        <h1 className="main-title">Industries</h1>
        <div className="container">
          {inds.map((e) => {
            return (
              <Fade key={e.id} bottom>
                <Industry props={e} />
              </Fade>
            );
          })}
        </div>
      </div>
      {/* End Industries */}
    </div>
  );
};

export default Home;
