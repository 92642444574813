import React from "react";
import "./agency.css";
import Zoom from "react-reveal/Zoom";

const Agency = () => {
  return (
    <div className="agency">
      <h2 className="main-title">Agency</h2>
      <div className="container">
        <Zoom>
          <div className="box">
            <img src={require("../../imgs/elektrobudowa.jpeg")} alt="Agency" />
            <h4>Elektrobudowa (Poland)</h4>
          </div>
        </Zoom>
        <Zoom>
          <div className="box">
            <img src={require("../../imgs/elecktromontaz.jpeg")} alt="Agency" />
            <h4>Elecktromontaz (Poland)</h4>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default Agency;
