import React from "react";
import "./indSection.css";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";

const IndSection = ({ props }) => {
  return (
    <div className="indsection" style={{ "--clr": props.color }}>
      <h2 className="main-title">{props.name}</h2>
      <Zoom cascade>
        <div className="container">
          <img src={require(`../../imgs/${props.img}`)} alt="Industry" />
          <div className="text">
            <Zoom>
              <h2>{props.company}</h2>
              <p>{props.text}</p>
              <Link to={`/${props.url}`}>More Information</Link>
            </Zoom>
          </div>
        </div>
      </Zoom>
    </div>
  );
};

export default IndSection;
